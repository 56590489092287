import { InfoOutlinedIcon } from '@components/UI/Icons/info'
import { StyledTooltip } from '@components/UI/Tooltip'
import { Carousel } from '@components/common/components/Carousel'
import { OmniChannelCarouselWrapper, SwiperDiv, TooltipWrapper } from './OmnichannelCarousel.style'

import OmniChannelLink from './components/OmniChannelLink'
import { normalizedTeaserText } from '@utils/helpers'

export const OmniChannelCarousel = ({ placements }) => {
  const omnichannelData = placements?.find(x => x.name === 'PDP_omnichannel_carousel')
  const omnichannelItems = omnichannelData?.items

  return omnichannelItems?.length > 1 ? (
    <OmniChannelCarouselWrapper>
      <Carousel slidesPerView={1} spaceBetween={30} loop={true} renderDefaultArrows>
        {omnichannelItems?.map((i, index) => (
          <SwiperDiv key={`n1-info__${index}`}>
            <OmniChannelLink
              actionSettings={i.teaserLXCallToActionSettings[0]}
              data-element-id={`X_X_1Placement_OmniChannel${index}_CTA`}
              icon={i.teaserIcon}
              title={i.teaserTitle4}
            />
            {i.teaserText2 && (
              <StyledTooltip text={normalizedTeaserText(i.teaserText2)} placement="top-start">
                <TooltipWrapper>
                  <InfoOutlinedIcon />
                </TooltipWrapper>
              </StyledTooltip>
            )}
          </SwiperDiv>
        ))}
      </Carousel>
    </OmniChannelCarouselWrapper>
  ) : (
    <OmniChannelCarouselWrapper>
      {omnichannelItems?.map((i, index) => (
        <SwiperDiv key={index}>
          <OmniChannelLink
            actionSettings={i.teaserLXCallToActionSettings[0]}
            data-element-id={`X_X_1Placement_OmniChannel${index}_CTA`}
            icon={i.teaserIcon}
            title={i.teaserTitle4}
          />
          {i.teaserText2 && (
            <StyledTooltip text={normalizedTeaserText(i.teaserText2)}>
              <TooltipWrapper>
                <InfoOutlinedIcon />
              </TooltipWrapper>
            </StyledTooltip>
          )}
        </SwiperDiv>
      ))}
    </OmniChannelCarouselWrapper>
  )
}
