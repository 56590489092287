import { StyledFormControlLabel } from '@components/UI/Checkbox/StyledFormControlLabel'
import { StyledRadio } from '@components/UI/Checkbox/StyledRadio'
import { StyledRadioGroup } from '@components/UI/Checkbox/StyledRadioGroup'
import { PlpCompactWidthListingIcon, PlpFullWidthListingIcon } from '@components/UI/Icons'
import { LX_SEARCH_PAGE_PRODUCT_TYPE } from '@constants/product'
import { IFacet, IPlpProductArgs } from '@features/plp/query'
import { setProductsMobileListingLayout } from '@features/ui/action'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import useBreakpoints from '@hooks/useBreakpoints'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import useTheme from '@mui/material/styles/useTheme'
import { SingleSortOrderOption, SortOrderOptions } from '@redux/reducers'
import { sortOrderOptionsSelector } from '@redux/selectors/site'
import { isEqual } from '@utils/helpers'
import { formattedParams, fromUrlParamsToObject } from '@utils/url'
import { useTranslation } from 'next-i18next'
import { forwardRef, useRef, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListingButtonsWrapper,
  PLPFilterSkeletonWrapper,
  SortByContainer,
  SortByContent,
  SortByWrapper,
  StyledCompactListingButton,
  StyledFullWidthListingButton,
} from '../PlpHeader.style'

import { useRouter } from 'next/router'
import { StyledFilterCTA, StyledSortByCTA } from '@components/UI/Pill/Pill.style'
import { IconChevronDown, IconChevronUp, IconFilter } from '@components/UI/Icons/VD/General'
import { usePlpDispatch } from '@utils/Plp/PlpContext'
import { TOGGLE_DRAWER_FILTERS } from '@utils/Plp/PlpReducer'

const CONTACT_LENSES = ['contactLenses', 'contactLensesAccessories']
// If prod is not CL or CL Accessories the sorting is always visible
const controlNewestSorting = facets => {
  const getProductType = facets?.find(catalog => catalog.name === LX_SEARCH_PAGE_PRODUCT_TYPE)
  if (!getProductType) return true
  const findFilters = getProductType?.entry.findIndex(el => {
    return CONTACT_LENSES.includes(el.label)
  })
  return findFilters === -1
}

const PLPHeaderFilter = forwardRef<
  HTMLDivElement,
  {
    appliedFiltersNumber: number
    facets: IFacet[]
    catalogLoading?: boolean
  }
>(({ appliedFiltersNumber, facets, catalogLoading }, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const plpDispatch = usePlpDispatch()
  const { isDesktop } = useBreakpoints()
  const sortOrderOptions = useSelector(sortOrderOptionsSelector) as SortOrderOptions
  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)
  const router = useRouter()
  const basePath = router.asPath.split('?')[0]
  const searchParams = router.asPath.split('?')[1]
  const queryParams: IPlpProductArgs = fromUrlParamsToObject(searchParams)

  const selectedSortOption = queryParams.sortBy ?? '0'
  const searchTerm = queryParams.searchTerm ?? ''
  const selectedFacets = queryParams.facet
    ? Array.isArray(queryParams.facet)
      ? queryParams.facet
      : [queryParams.facet]
    : []

  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false)
  const sortByDivRef = useRef<HTMLDivElement>(null)
  const isVisibleNewestSorting = controlNewestSorting(facets)

  const onSortingSubmit = (sortValue: SingleSortOrderOption) => {
    if (isEqual(sortValue.value, selectedSortOption)) {
      return
    }

    const params: IPlpProductArgs = {
      facet: selectedFacets,
      searchTerm: searchTerm || undefined,
      sortBy: sortValue.value === '0' ? undefined : (sortValue.value as string),
    }

    const newParams = formattedParams(params)
    const url = `${basePath}${newParams}`
    router.push(url, undefined, { shallow: true })
    setFilterMenuOpen(false)
  }

  const handleClick = () => {
    setFilterMenuOpen(!filterMenuOpen)
  }

  const handleTouchEnd = e => {
    e.preventDefault()
    setFilterMenuOpen(!filterMenuOpen)
  }

  const renderIcon = () => (filterMenuOpen ? <IconChevronUp /> : <IconChevronDown />)

  return (
    <>
      {!isDesktop ? (
        <>
          {catalogLoading ? (
            <>
              <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
                <Skeleton height={34} />
              </SkeletonTheme>
            </>
          ) : (
            <SortByWrapper ref={ref}>
              <StyledSortByCTA
                data-name="sortBy"
                data-element-id="X_X_MainNav_Filter"
                label={t('ProductFilter.Labels.sortBy')}
                icon={renderIcon()}
                onTouchEnd={handleTouchEnd}
                onClick={handleClick}
              />

              <div ref={sortByDivRef}>
                {filterMenuOpen ? (
                  <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
                    <SortByContent isVisible={true} filtersApplied={appliedFiltersNumber > 0}>
                      <SortByContainer>
                        <StyledRadioGroup defaultValue={selectedSortOption}>
                          {Object.keys(sortOrderOptions).map(key => {
                            const sortName = key.toLowerCase()
                            const sortProfileName = sortOrderOptions[key].profileName
                            const sortValue = sortOrderOptions[key].value ?? '0'
                            if (!isVisibleNewestSorting && sortName === 'newarrivals') return
                            return (
                              <StyledFormControlLabel
                                data-name={sortName}
                                key={`${sortProfileName}__${sortValue || 0}`}
                                value={sortValue}
                                control={<StyledRadio />}
                                label={t(`ProductGrid.SortOptions.${sortName}`)}
                                onChange={() => onSortingSubmit(sortOrderOptions[key])}
                              />
                            )
                          })}
                        </StyledRadioGroup>
                      </SortByContainer>
                    </SortByContent>
                  </ClickAwayListener>
                ) : null}
              </div>

              {/* Mobile listing views (compact and full) */}
              <ListingButtonsWrapper>
                <StyledCompactListingButton aria-label="CompactListingIcon">
                  <PlpCompactWidthListingIcon
                    onClick={() => dispatch(setProductsMobileListingLayout('compact'))}
                    htmlColor={
                      productsMobileListingLayout === 'compact'
                        ? `${theme.palette.custom.cyprus}`
                        : `${theme.palette.custom.boulder}`
                    }
                  />
                </StyledCompactListingButton>
                <StyledFullWidthListingButton aria-label="FullListingIcon">
                  <PlpFullWidthListingIcon
                    onClick={() => dispatch(setProductsMobileListingLayout('full'))}
                    htmlColor={
                      productsMobileListingLayout === 'full'
                        ? `${theme.palette.custom.cyprus}`
                        : `${theme.palette.custom.boulder}`
                    }
                  />
                </StyledFullWidthListingButton>
              </ListingButtonsWrapper>
              <StyledFilterCTA
                id="filterCTA"
                key="filterCTA"
                data-name="filterBy"
                data-element-id="X_X_MainNav_Filter"
                label={t('ProductFilter.Labels.filterBy')}
                icon={<IconFilter />}
                onClick={() => {
                  plpDispatch({
                    type: TOGGLE_DRAWER_FILTERS,
                    payload: true,
                  })
                }}
              />
            </SortByWrapper>
          )}
        </>
      ) : (
        <SortByWrapper filtersApplied={appliedFiltersNumber > 0} ref={ref}>
          {catalogLoading ? (
            <PLPFilterSkeletonWrapper>
              <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
                <Skeleton
                  style={{
                    width: 102,
                    height: 34,
                    borderRadius: 40,
                    marginRight: 16,
                  }}
                />
                <Skeleton style={{ width: 99, height: 34, borderRadius: 40 }} />
              </SkeletonTheme>
            </PLPFilterSkeletonWrapper>
          ) : (
            <>
              <StyledSortByCTA
                data-name="sortBy"
                data-element-id="X_X_MainNav_Filter"
                label={t('ProductFilter.Labels.sortBy')}
                icon={renderIcon()}
                onTouchEnd={handleTouchEnd}
                onClick={handleClick}
              />

              <div ref={sortByDivRef}>
                {filterMenuOpen ? (
                  <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
                    <SortByContent isVisible={true} filtersApplied={appliedFiltersNumber > 0}>
                      <SortByContainer>
                        <StyledRadioGroup defaultValue={selectedSortOption}>
                          {Object.keys(sortOrderOptions).map(key => {
                            const sortName = key.toLowerCase()
                            const sortProfileName = sortOrderOptions[key].profileName
                            const sortValue = sortOrderOptions[key].value ?? '0'
                            if (!isVisibleNewestSorting && sortName === 'newest') return
                            return (
                              <StyledFormControlLabel
                                data-name={sortName}
                                key={`${sortProfileName}__${sortValue || 0}`}
                                value={sortValue}
                                control={<StyledRadio />}
                                label={t(`ProductGrid.SortOptions.${sortName}`)}
                                onChange={() => onSortingSubmit(sortOrderOptions[key])}
                              />
                            )
                          })}
                        </StyledRadioGroup>
                      </SortByContainer>
                    </SortByContent>
                  </ClickAwayListener>
                ) : null}
              </div>

              <StyledFilterCTA
                id="filterCTA"
                key="filterCTA"
                data-name="filterBy"
                data-element-id="X_X_MainNav_Filter"
                label={t('ProductFilter.Labels.filterBy')}
                icon={<IconFilter />}
                onClick={() => {
                  plpDispatch({
                    type: TOGGLE_DRAWER_FILTERS,
                    payload: true,
                  })
                }}
              />
            </>
          )}
        </SortByWrapper>
      )}
    </>
  )
})

export default PLPHeaderFilter
