import { FC } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { getTopReasonsIconByName } from './helpers'
import { StyledListItem, StyledTopReasonsTeasableItem } from './TopReasons.style'
import convertToNextLink from '@components/UI/ConvertToNextLink'

export interface TopReasonsProps {
  topReasonsCMCollection: ICMCollection
}

const TopReasons: FC<TopReasonsProps> = ({ topReasonsCMCollection }) => {
  const topReasonsTeasableItems = topReasonsCMCollection?.teasableItems.filter(isLXTeaser) || []

  return (
    <Box component="div">
      <Typography variant="subtitle2">{topReasonsCMCollection.collectionTitle}</Typography>
      <List>
        {topReasonsTeasableItems.map((teasableItem, i) => {
          const { id, teaserIcon: teaserIconName, teaserText2 } = teasableItem
          const htmlContent = convertToNextLink({ html: teaserText2, dataNamePrefix: 'topReason' })

          return (
            <StyledListItem key={id} disablePadding>
              {getTopReasonsIconByName(teaserIconName)}{' '}
              <StyledTopReasonsTeasableItem>{htmlContent}</StyledTopReasonsTeasableItem>
            </StyledListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default TopReasons
