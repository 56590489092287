import { Typography } from '@mui/material'
import { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const PlpHeaderTitle: FC<{
  text?: string
  isSticky?: boolean
  catalogLoading?: boolean
}> = ({ text = '', catalogLoading }) => (
  <Typography variant="h5" component="h1">
    {catalogLoading ? (
      <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
        <Skeleton containerClassName="dc_plp_header_skeleton" />
      </SkeletonTheme>
    ) : (
      text || (
        <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
          <Skeleton containerClassName="dc_plp_header_skeleton" />
        </SkeletonTheme>
      )
    )}
  </Typography>
)

export default PlpHeaderTitle
