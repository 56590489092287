import { StyledPLPFilteredContent } from '@components/PlpFilter/PlpFilter.style'
import PlpFilterAlgolia from '@components/PlpFilter/PlpFilterAlgolia'
import { ProductGridLayoutAlgolia } from '@components/ProductGrid'
import { AlgoliaProductListInitSSR } from '@components/common/components/AlgoliaInit'
import { ICategory } from '@features/category/query'
import { ICommerceHclPage } from '@typesApp/cms'
import { IAlgoliaHit } from '@typesApp/product'
import { IPage } from '@typesApp/seo'
import { PlpProvider, usePlpDispatch } from '@utils/Plp/PlpContext'
import { IPlpState, SET_FIRST_LOAD } from '@utils/Plp/PlpReducer'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import React, { useEffect } from 'react'
import { useSearchBox } from 'react-instantsearch'
import LayoutWithSidebar from '@layouts/LayoutWithSidebar'
import { getProductBreadcrumbs } from '@components/PagesSeo/product/Product'
import { useTranslation } from 'next-i18next'
import BreadcrumbLayout from '@components/breadcrumb/BreadcrumbLayout'
import Container from '@components/UI/Container'
import { StyledPlpWrapper } from './Plp.style'

interface PLP {
  page: IPage
  plpState: IPlpState
  categoryData: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  ungroupedHits: IAlgoliaHit[]
  plpCommerce?: ICommerceHclPage
  isLocaleDomain: boolean
  locationOrigin: string
}

const VirtualSeachBox = ({ algoliaQueryParam }) => {
  const plpDispatch = usePlpDispatch()
  const { refine } = useSearchBox()
  useEffect(() => {
    if (algoliaQueryParam) {
      plpDispatch({ type: SET_FIRST_LOAD, payload: true })
      refine(algoliaQueryParam)
    }
  }, [algoliaQueryParam, plpDispatch, refine])
  return null
}

const Plp: React.FC<PLP> = ({
  categoryData,
  parentCatalogGroupID,
  plpState,
  plpCommerce,
  isLocaleDomain,
  locationOrigin,
}) => {
  const searchParams: ReadonlyURLSearchParams & { query?: string } = useSearchParams()
  const { categoryId, categoryIdentifier, algoliafilterCategory, groupedIndexName, serverUrl, locale } = plpState
  const sidebarNav = plpCommerce?.commercePlacements.find(placement => placement.name === 'header_left_side_navigation')
  const breadcrumbs = getProductBreadcrumbs(categoryData)
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <BreadcrumbLayout
          breadcrumbsList={breadcrumbs}
          cid={`category-products-${categoryIdentifier}`}
          langCode={plpState.locale.replace('_', '-')}
          searchTermText={`${t('ProductGrid.Labels.searchResults')}`}
          isLocaleDomain={isLocaleDomain}
          locale={locale}
          locationOrigin={locationOrigin}
        />
      </Container>
      <StyledPlpWrapper>
        <LayoutWithSidebar sidebarNav={sidebarNav}>
          <PlpProvider initialState={plpState}>
            <AlgoliaProductListInitSSR
              plpSSRInitParams={plpState.serverState}
              plpInitParams={{
                groupedIndexName,
                serverUrl,
                locale,
                algoliafilterCategory,
                configure: {
                  filters: algoliafilterCategory,
                },
              }}
            >
              {searchClient => (
                <>
                  <VirtualSeachBox algoliaQueryParam={searchParams?.query ?? ''} />
                  <PlpFilterAlgolia searchClient={searchClient} />
                  <StyledPLPFilteredContent id={categoryIdentifier}>
                    <ProductGridLayoutAlgolia
                      cid={`category-products-${categoryIdentifier}`}
                      categoryId={categoryId}
                      searchClient={searchClient}
                      categoryFilter={algoliafilterCategory}
                      categoryData={categoryData}
                      parentCatalogGroupID={parentCatalogGroupID}
                      plpCommerce={plpCommerce}
                    />
                  </StyledPLPFilteredContent>
                </>
              )}
            </AlgoliaProductListInitSSR>
          </PlpProvider>
        </LayoutWithSidebar>
      </StyledPlpWrapper>
    </>
  )
}

export default Plp
