import styled from '@mui/material/styles/styled'

export const StyledPlacementsWrapper = styled('div', {
  name: 'Placements',
  slot: 'Wrapper',
})(({ theme }) => ({
  position: 'relative',

  '#main_placement_1': {
    '> div': {
      padding: theme.spacing(0),

      // fix for offers page text
      '> div': {
        textAlign: 'left',
        width: '100%',
      },
    },
  },
}))

export const StyledPlacementsLoaderWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))
