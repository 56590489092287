import { PRODUCT_ATTRIBUTES_IDENTIFIERS, MEASURES_UNITS } from '@utils/productAttributes'
import { MeasureUnit } from '@typesApp/product'
import { Cart, IOrderItem } from '@typesApp/order'
import { IAddItemArgs } from '@features/order/thunks/addItem'

interface checkIfProductExistInCartArgs {
  cart: Cart | undefined
  addItemtArgs: IAddItemArgs
}

export const getCLSpherePowerRange = (powerArray: string[], t) => {
  const positiveValues: number[] = []
  const negativeValues: number[] = []

  const POSITIVE_SIGN = '+'
  const NEGATIVE_SIGN = '-'

  powerArray.forEach(value => {
    const parsedNumValue = +value
    if (parsedNumValue >= 0 && value?.startsWith(POSITIVE_SIGN)) {
      positiveValues.push(parsedNumValue)
    }

    if (parsedNumValue <= 0 && value?.startsWith(NEGATIVE_SIGN)) {
      negativeValues.push(parsedNumValue)
    }
  })

  const minPositive = Math.min(...positiveValues).toFixed(2)
  const maxPositive = Math.max(...positiveValues).toFixed(2)
  const minNegative = Math.min(...negativeValues).toFixed(2)
  const maxNegative = Math.max(...negativeValues).toFixed(2)

  const minAndMaxValues = {
    minPositive: `+${minPositive}`,
    maxPositive: `+${maxPositive}`,
    minNegative: +minNegative === 0 ? `-${minNegative}` : `${minNegative}`,
    maxNegative: +maxNegative === 0 ? `-${maxNegative}` : `${maxNegative}`,
  }

  const positiveRange =
    minPositive === maxPositive
      ? `${minAndMaxValues.minPositive}D`
      : `${minAndMaxValues.minPositive}D ${t('ProductDetails.Attributes.To')} ${minAndMaxValues.maxPositive}D`
  const negativeRange =
    minNegative === maxNegative
      ? `${minAndMaxValues.minNegative}D`
      : `${minAndMaxValues.maxNegative}D ${t('ProductDetails.Attributes.To')} ${minAndMaxValues.minNegative}D`

  const spherePowerTemplate = `${positiveRange} | ${negativeRange}`

  return {
    spherePowerTemplate,
  }
}

export const renderValueWithUnit = (value: string | string[], unit: MeasureUnit): string => {
  if (Array.isArray(value)) {
    return value.map(item => `${item} ${unit}`).join('; ')
  } else {
    return `${value} ${unit}`
  }
}

export const getAttributeValue = (attrKey, currentProduct, templeColor, t) => {
  const numberRegex = /^\d+$/
  const value = currentProduct?.productAttributes[attrKey]
  const milimetersLabel = MEASURES_UNITS.MM
  const dktLabel = MEASURES_UNITS.DKT

  switch (attrKey) {
    case PRODUCT_ATTRIBUTES_IDENTIFIERS.FRONT_COLOR:
      return templeColor && currentProduct?.productAttributes[attrKey] === templeColor
        ? currentProduct?.productAttributes[attrKey]
        : `${currentProduct?.productAttributes[attrKey]} - ${templeColor}`
    case PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_SPHERE_POWER:
      const clLensRange = currentProduct?.productAttributes[PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_LENS_RANGE] || []
      const extractedSpherePowerValues = clLensRange?.map((node: string) => node?.split(' ')[1]) || []

      const { spherePowerTemplate } = getCLSpherePowerRange(extractedSpherePowerValues, t)
      return spherePowerTemplate
    case PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_DIAMETER:
      return renderValueWithUnit(value, milimetersLabel)
    case PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_BASE_CURVE:
      return renderValueWithUnit(value, milimetersLabel)
    case PRODUCT_ATTRIBUTES_IDENTIFIERS.CL_GAS_PERMEABILITY:
      return renderValueWithUnit(value, dktLabel)

    default:
      if (numberRegex.test(attrKey)) {
        return parseInt(attrKey) + t('ProductDetails.Attributes.Percentage')
      } else {
        return currentProduct?.productAttributes[attrKey]
      }
  }
}

export const filterAttribute = (attrKey: string, attributesKeyToDisplay) => {
  return attrKey && attributesKeyToDisplay.includes(attrKey)
}

export const checkIfProductExistInCart = ({
  cart,
  addItemtArgs,
}: checkIfProductExistInCartArgs): IOrderItem | undefined => {
  return cart?.orderItem?.find(
    (item: IOrderItem) => item?.partNumber === addItemtArgs.partnumber && item?.freeGift !== 'true'
  )
}
