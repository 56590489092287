import { ContactLensesData } from '@typesApp/prescription'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'
import { RX_CONF_SELECTOR, SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { PRODUCT_URL_SEARCH_PARAMS } from '../../../constants/product'
import { CART } from '../../../constants/routes'
import { orderApi } from '../../../features/order/query'
import { addItem } from '../../../features/order/thunks'
import addContactLenses, { ContactLensesAddItemArgs } from '../../../features/order/thunks/addContactLenses'
import addLensItem, { IAddLensArgs } from '../../../features/order/thunks/addLensItem'

import { createUserIdentity } from '@utils/user'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { productAddToCartEnabledSelector, productContactLensesDataSelector } from '../../../features/product/selector'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { Cart, IOrderItem } from '../../../types/order'
import { IProduct } from '../../../types/product'
import { LensObject } from '../../../types/rxConfigurator'
import { isFormattedProductCLAccessories, isFormattedProductContactLenses } from '../../../utils/product'
import { getInsuranceEventModule } from '@components/DirectBilling'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { cartSelector } from '@features/order/selector'
import { sendAddToCartClickEvent, sendServerErrorEvent } from '@foundation/analytics/tealium/lib'
import { sendSuccessMessage } from '@features/success/slice'
import { ORDER_TOTAL_THRESHOLD_REACHED } from '@constants/order'
import { updateIsCartThresholdReachedFlag } from '@features/order/slice'
import { isRememberMeEnabledSelector } from '@redux/selectors/site'
import { checkIfProductExistInCart } from '../utils/index'
import { IAddItemArgs } from '@features/order/thunks/addItem'

export type customParams = Partial<ContactLensesAddItemArgs> | Partial<IAddLensArgs>

export interface AddToCartProps {
  isClItem?: boolean
  isLenses?: boolean
  product?: IProduct
  fromConfigurator?: boolean
  lensObject?: LensObject
  images?: ImageryType
  customerSegments?: string[]
  cart?: Cart
  customParams?: customParams
}

export const useAddOrderItem = (
  productInCart?: IOrderItem,
  currentProduct?: IProduct,
  partNumber?: string,
  productQuantity?: string[]
) => {
  const { mySite } = useSite()
  const router = useRouter()
  const { langId } = useStoreIdentity()
  const dispatch = useDispatch()
  const contactLensData = useSelector(productContactLensesDataSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const query = useSearchParams()
  const [removeOrderItem] = orderApi.endpoints.deleteOrderItem.useLazyQuery()
  const isClAccessory = isFormattedProductCLAccessories(currentProduct?.productAttributes['PRODUCT_TYPE'] || '')
  const isCl = isFormattedProductContactLenses(currentProduct?.productAttributes['PRODUCT_TYPE'] || '')
  const isEditingContactLens = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS)
  const isEditingLenses = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_LENS)
  const customerSegments = useCustomerSegmentsUtil()
  const cart = useSelector(cartSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)

  const buildContactLensesPayload = (contactLensData: ContactLensesData): ContactLensesAddItemArgs['items'] => {
    const clPayload: ContactLensesAddItemArgs['items'] = !!contactLensData
      ? Object.values(contactLensData).map(entry => {
          const singleLensData = { ...entry }
          const { quantity } = singleLensData
          delete singleLensData.quantity
          return {
            x_contactLens: {
              ...singleLensData,
            },
            quantity: quantity || '',
          }
        })
      : []
    return clPayload
  }

  const removeItemFromCart = useCallback(
    async (orderItem: IOrderItem) => {
      await removeOrderItem({
        storeId: mySite.storeID,
        orderItem,
      })
    },
    [isEditingContactLens]
  )

  const createAddCLItemArgs = (customParams?: customParams): ContactLensesAddItemArgs => {
    const baseParams: ContactLensesAddItemArgs = {
      items: contactLensData ? buildContactLensesPayload(contactLensData) : [],
      product: currentProduct,
      callback: () => {
        router.push(`/${CART}`)
      },
      langId,
      customerSegments,
      skipServerErrorEvent: true,
    }
    return customParams ? { ...baseParams, ...customParams } : baseParams
  }

  const createAddLensesItemsArgs = ({ lensObject, images, customParams }: AddToCartProps): IAddLensArgs => {
    const baseParams: IAddLensArgs = {
      params: {
        catentryId: lensObject?.lensPackage.catEntryId || '',
        langId,
        partnumber: partNumber || '',
        product: currentProduct,
        quantity: ['1'],
        images,
      },
      callback: () => {
        if (window.RXC) {
          window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
        }
        router.push(`/${CART}`)
      },
      siteInfo: mySite,
      customerSegments,
    }
    return customParams ? { ...baseParams, ...customParams } : baseParams
  }

  const createAddItemArgs = ({ fromConfigurator, customParams }: AddToCartProps): IAddItemArgs => {
    const baseParams = {
      partnumber: currentProduct?.items?.[0]?.partNumber || partNumber || '',
      quantity: isClAccessory ? productQuantity || [''] : ['1'],
      langId,
      product: currentProduct,
      customerSegments,
      callback: fromConfigurator
        ? () => {
            if (window.RXC) {
              window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
            }
            if (router.route !== `/${CART}`) {
              router.push(`/${CART}`)
            }
          }
        : () => {
            if (router.route !== `/${CART}`) {
              router.push(`/${CART}`)
            }
          },
    }
    return customParams ? { ...baseParams, ...customParams } : baseParams
  }

  const addItemToCart = async ({
    isClItem,
    isLenses,
    fromConfigurator,
    lensObject,
    images,
    cart,
    customParams,
  }: AddToCartProps) => {
    let response

    await createUserIdentity(dispatch, isRememberMeEnabled)

    if (isClItem) {
      const addCLItemArgs = createAddCLItemArgs(customParams)
      response = await dispatch(addContactLenses(addCLItemArgs))
    } else if (isLenses) {
      const addLensesItemsArgs = createAddLensesItemsArgs({ lensObject, images, customParams })
      response = await dispatch(addLensItem(addLensesItemsArgs))
    } else {
      const addItemtArgs = createAddItemArgs({ fromConfigurator, customParams })
      const productAlreadyInCart = checkIfProductExistInCart({ cart, addItemtArgs })

      if (productAlreadyInCart) {
        response = await updateItem(
          (parseInt(addItemtArgs?.quantity[0]) + parseInt(productAlreadyInCart?.quantity)).toString(),
          productAlreadyInCart?.orderItemId,
          currentProduct,
          addItemtArgs?.customerSegments || [],
          addItemtArgs?.callback
        )
      } else {
        response = await dispatch(addItem(addItemtArgs))
      }
    }

    const hasCartError = checkCartError(response)

    if (response?.error && response.meta.arg.skipServerErrorEvent) {
      sendServerErrorEvent(response.payload)
    }

    dispatch(updateIsCartThresholdReachedFlag(hasCartError))
  }

  const [updateCart] = orderApi.endpoints.getCart.useLazyQuery()
  const [updateOrderItem] = orderApi.endpoints.updateOrderItem.useLazyQuery()

  const checkCartError = (response: any) => {
    return response?.payload?.response?.data?.errors[0].errorCode == ORDER_TOTAL_THRESHOLD_REACHED
  }

  const updateItem = async (
    quantity: string,
    orderItemId: string,
    product: IProduct | undefined,
    customerSegments: string[],
    callback?: any
  ) => {
    await updateOrderItem({
      storeId: mySite.storeID,
      body: {
        orderItem: [
          {
            quantity: quantity,
            orderItemId: orderItemId,
          },
        ],
      },
    })
    await updateCart({
      storeId: mySite.storeID,
      currency: mySite.defaultCurrencyID || '',
    })

    dispatch(
      sendSuccessMessage({
        key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
        link: {
          url: CART,
          textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
        },
      })
    )

    const productsWithQuantity = product
      ? [
          {
            ...product,
            quantity: quantity || '1',
            id: product.uniqueID,
          },
        ]
      : []
    sendAddToCartClickEvent(productsWithQuantity, customerSegments)

    callback?.()
  }

  const addToCart = async (props: AddToCartProps = {}) => {
    if (!addToCartEnabled) {
      return
    }

    const { interceptAddCartItem } = getInsuranceEventModule()
    interceptAddCartItem(() => addToCartImpl(props))
  }

  const addToCartImpl = async ({
    isClItem = isCl,
    isLenses,
    product,
    fromConfigurator,
    lensObject,
    customParams,
    images,
  }: AddToCartProps = {}): Promise<void> => {
    if (!addToCartEnabled) {
      return
    }

    if (isEditingContactLens || isEditingLenses) {
      !!productInCart && (await removeItemFromCart(productInCart))
      addItemToCart({
        isClItem,
        isLenses,
        product,
        fromConfigurator,
        lensObject,
        images,
        cart,
        customParams,
      })
    } else {
      addItemToCart({
        isClItem,
        isLenses,
        product,
        fromConfigurator,
        lensObject,
        images,
        cart,
        customParams,
      })
    }
  }

  const handleAddToCartLenses = (_, lensObject: LensObject, ...args): void => {
    // when we call the configurator with the cartMode object
    // the position of the image arg, isn't the same as the usual
    const images: ImageryType = args.find(arg => !!arg?.fallbackImage || !!arg?.productImage)

    if (lensObject.lensPackage.type === 'Frame Only') {
      addToCart({ fromConfigurator: true })
    } else {
      addToCart({ isLenses: true, lensObject, images })

      // @TODO add to cart RX analytics
      // if (currentProduct) {
      //   tealiumService.sendAddToCartEvent({
      //     ...getProductWithSoldoutStatusForAnalytics(currentProduct, soldOutStatus!),
      //     rxPrice: +lensObject.lensPackage.listPrice,
      //   })
      // }
    }
  }

  return {
    addToCart,
    handleAddToCartLenses,
    addItemToCart,
  }
}
