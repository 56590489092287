//Standard libraries
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

//UI
import { loginStatusSelector } from '../../features/user/selector'
import { sendNotFoundErrorEvent } from '../../foundation/analytics/tealium/lib'
import { StyledBodyContainer } from './NotFound.style'
import { ICommerceHclPage, IPageStaticLanding } from '@typesApp/cms'
import PlacementLayout from '@components/Cms/PlacementLayout'
import { setSeoData } from '@features/seo/slice'
import { isEmpty } from '@utils/helpers'

type Props = {
  pageData: IPageStaticLanding | ICommerceHclPage | null
}

const NotFound = ({ pageData }: Props) => {
  const router = useRouter()
  const loginStatus = useSelector(loginStatusSelector)
  const data = pageData as IPageStaticLanding

  useEffect(() => {
    sendNotFoundErrorEvent({
      loginStatus,
      requestedUrl: router.pathname,
    })
  }, [loginStatus, router.pathname])

  const seoHeadData = {
    page: {
      title: data?.htmlTitle,
      metaDescription: data?.htmlDescription,
    },
  }
  const dispatch = useDispatch()
  dispatch(setSeoData(!isEmpty(seoHeadData) ? seoHeadData : null))

  if (!data) {
    return null
  }

  const contentPlacements = data?.contentPlacements || []

  return (
    <StyledBodyContainer>
      <PlacementLayout placements={contentPlacements} className={data.cssClassName} />
    </StyledBodyContainer>
  )
}

export default NotFound
